#root{
    width: 100%;
    position: fixed;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
.App{
    display: flex;
    width: 100%;
    // position: fixed;
    // top: 0;
    // left: 0;
    background-color: #fff;
    // padding: 10px;
    flex-direction: column;
    // overflow-y: scroll;
    header{
        // position: sticky;
        // padding: 16px;
        button{
            .icon{
                margin-right: 6px;
            }
        }
        padding: 6px;
        .logo{
            text-align: center;
            img{
                width: 200px;
            }
        }
    }
    .app-body{
        flex: 100%;
        padding: 0px 6px 6px;
        overflow-x: hidden;
        // overflow-y: auto;
        .tab-config{
            .ant-tabs-tab{
                border-width: 2px;
            }
            *{
                font-size: 13px;
            }
            .ant-tabs-nav{
                margin-bottom: 0px;
            }
            .ant-tabs-tab{
                padding: 3px 8px;
            }
            .ant-tabs-tab-active{
                .ant-tabs-tab-btn{
                    color: rgba(0, 0, 0, 0.88);
                }
            }
            .ant-tabs-content-holder{
                border: 2px solid rgba(5, 5, 5, 0.06);
                padding: 6px;
                border-top: 0px;
                border-radius: 6px;
                border-top-left-radius: 0px;
            }

            .app-config{
                border-radius: 5px;
                padding: 10px 6px;
                position: relative;
                margin-bottom: 16px;
                margin-top: 6px;
                border: 2px solid rgba(5, 5, 5, 0.06);
                .title{
                    position: absolute;
                    top: -12px;
                    left: 10px;
                    padding: 0px 4px;
                    background-color: #fff;
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    .ant-checkbox-wrapper{
                        display: flex;
                        align-items: center;
                    }
                }
                .ant-checkbox-wrapper, .ant-radio-wrapper{
                    span:last-child{
                        padding-right: 0px!important;
                    }
                }
                .ant-form-item{
                    label{
                        span:last-child{
                            padding-left: 4px;
                        }
                    }
                }
            }
        }
    }
    .app-footer{
        display: flex;
        align-items: center;
        margin: 0px -3px;
        padding: 6px;
        button{
            flex: 1 1 auto;
            margin: 0px 3px;
        }
    }
}